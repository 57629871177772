body {
  display: flex; 
  flex-direction: column;
  margin: 0 auto;
  height: 100vh !important;
  height: -webkit-fill-available !important;
}
html {
  height: -webkit-fill-available !important;
}

.container {
  padding: 0 2rem;
}

.page-header {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.4rem;
}

.main {
  min-height: 100vh; /* calc(100vh - 81px) need to find a better way for mobile */
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #E5E5E5;
  overflow: scroll;
}

.main-content {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
}

.auth-bg::before{
  background: #252547;
  clip-path: polygon(0 0%, 100% 0, 100% 70%, 0 100%);
  display: block;
  content: "";
  width: 100%;
  height: 62vh;
  position: absolute;
  top: 0;
  z-index: 0;
}

.form-header {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2em;
}

.form-text {
  color: #64748B;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2em;
}

.form-sub-header {
  color: #0A0E39;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.2em;
}

.footer {
  display: flex;
  flex: 1;
  height: 5vh;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

.mobileNav{
  position: fixed;
  bottom: 0;
  padding-bottom:env(safe-area-inset-bottom);
  width: 100%;
  background: #1D1F31;
  padding: 8px;
}

.stickyCTA{
  background-color: #fff;
  left: 0;
  position: fixed;
  bottom: 0;
  padding-bottom:env(safe-area-inset-bottom);
  width: 100%;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.sideDrawer{
  padding-bottom: 72px;
}

audio{
  height: 40px;
}

input:disabled{
  background: #F3F4F6;
  color: #9CA3AF;
  opacity: 1 !important;
}

input:focus-visible, select:focus-visible, textarea:focus-visible{
  border-color: #4F46E5 !important;
    box-shadow: 0 0 0 1px #4F46E5 !important;
}

select{
  padding: 0 8px !important;
}

@media screen and (max-width: 991px) {
  .companySettings{
    padding-bottom: 68px;
  }

  /* .sideDrawer{
    padding-bottom: 72px;
  } */
}